import React, { memo } from "react";
import Theme from "./ThemeProvider";

import loadable from '@loadable/component';

const Background = loadable(() => import("../components/ThreeBackground"));
const Header = loadable(() => import("../components/Header"));
const Footer = loadable(() => import('../components/Footer'));

const Layout = memo((props) => {
    const color = (props.location.pathname === "/a-propos/" || props.location.pathname === "/a-propos")
        ? "#183E67"
        : (props.location.pathname === "/contact/" || props.location.pathname === "/contact")
            ? "#F0884E"
            : "#222222";
    return (
        <Theme
            pathname={props.location.pathname}
            backgroundColor={color}>
            <Background />
            <Header pathname={props.location.pathname} />
            {props.children}
            <Footer location={props.location.pathname} />
        </Theme>
    );
});

export default Layout;