import React, { memo } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import noise from "../images/noise.png";

const theme = {
    white: "#F3F3F3",
    black: "#222222",
    red: "#D4293C",
    blue: "#183E67",
    blueBright: "#2338F4",
    orange: "#FC5823",
    orangeBright: "#F0884E",
    texteSmall: "0.75rem",
    texteNormal: "14px",
    texteMedium: "1.333rem",
    texteLarge: "1.777rem",
    texteDisplay: "2.369rem",
    texteDisplayXL: "3.157rem",
    lightWeight: "300",
    normalWeight: "400",
    mediumWeight: "500",
    boldWeight: "600",
}

const GlobalStyle = createGlobalStyle`
    /* ###################################################### */
    /* ######################## BASE ######################## */
    /* ###################################################### */

    body {
        text-rendering: geometricPrecision;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-synthesis: style;

        background-color: ${props => props.backgroundColor || theme.black};
        transition: background 0.3s ease-in-out, margin 0.3s ease-in-out;

        font-family: "Poppins", sans-serif;
        font-size: ${theme.texteNormal};
        color: ${theme.white};
        margin: 50px;
        padding: 0px;

        &:after {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            background-image: url(${noise});
            background-repeat: repeat;
            pointer-events: none;
            user-select: none;
            width: 100vw;
            height: 100vh;
            opacity: 0.45;
            background-blend-mode: overlay;
            z-index: -1;
        }

        #gatsby-focus-wrapper {
            transition: min-height 0.8s ease-in-out, height 0.8s ease-in-out;
            min-height: ${props => (props.location === "/contact" || props.location === "/contact/") ? "0vh" : "calc(100vh - 100px)"};
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > .tl-edges {
                overflow: unset;
            }
        }

        .swiper-slide {
            width: auto;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        font-weight: ${theme.mediumWeight};
	    line-height: 39.29px;
        letter-spacing: -0.6632px;
        margin: 0px;
    }

    h1,
    .h1 {
        font-size: ${theme.texteDisplay};
        margin-bottom: 20px;

        &.display {
            font-size: ${theme.texteDisplayXL};
            font-weight: ${theme.boldWeight};
            margin-bottom: 25px;
        }
    }

    h2,
    .h2 {
        font-size: ${theme.texteLarge};
        margin-bottom: 15px;
    }

    h3,
    .h3 {
        font-size: ${theme.texteMedium};
        margin-bottom: 10px;
    }

    h4,
    h5,
    h6,
    .h4,
    .h5,
    .h6 {
        font-size: ${theme.texteMedium};
        margin-bottom: 10px;
    }

    p {
        font-size: ${theme.texteNormal};
        margin: 0px;
        margin-bottom: 10px;

        &.small {
            font-size: ${theme.texteSmall};
        }
    }

    span.small {
        font-size: ${theme.texteSmall};
    }

    a {
        text-decoration: none;
        color: ${theme.white};
        transition: color 0.5s ease;

        &:hover {
            text-decoration: none;
            color: ${theme.orange};
        }
    }

    strong,
    b {
        color: ${theme.white};
        font-weight: ${theme.boldWeight};
    }

    dl,
    ol,
    ul {
    }

    li {
    }

    button,
    .button {
        cursor: pointer;
        display: inline-block;
        font-size: ${theme.texteMedium};
        font-weight: ${theme.mediumWeight};
        background-color: ${theme.orange};
        padding: 8px 16px;
        color: ${theme.white};
        border: none;
        border-radius: 5px;
        line-height: 1;
        box-shadow: 0 0 50px rgba(252, 88, 35, 0.4);
        transition: transform .3s ease-out, background .3s ease-out;

        &:hover {
            transform: scale(1.08) !important;
            background-color: ${theme.red};
            color: ${theme.white} !important;
        }

        &:active {
            transform: scale(0.98) !important;
            background-color: ${theme.orangeBright};
            color: ${theme.white} !important;
        }
    }

    /* ########################################################### */
    /* ######################## STRUCTURE ######################## */
    /* ########################################################### */

    /* ######################## HEADER ######################## */

    header {
    }

    /* ######################## MAIN ######################## */

    main {
    }

    /* ######################## FOOTER ######################## */

    footer {
    }

    /* ######################## FORM ######################## */

    form {
        /* input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: relative;
            margin-right: 15px;
            height: 15px;
            width: 15px;
            border: 1px solid #000;
        }

        input[type="checkbox"]:checked ~ .checkmark {
            background-color: $red;
            border: none;
        }

        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        input[type="checkbox"]:checked ~ .checkmark:after {
            display: block;
        }

        .checkmark:after {
            left: 5px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        } */
    }

    /* ####################################################### */
    /* ######################## CLASS ######################## */
    /* ####################################################### */

    /* ################################################################# */
    /* ######################## CAS PARTICULIER ######################## */
    /* ################################################################# */

    .home {
    }

    /* ############################################################# */
    /* ######################## MOBILE ADAPT ####################### */
    /* ############################################################# */

    @media screen and (max-width: 992px) {
    }

    @media screen and (max-width: 767px) {
        body {
            margin: 20px;
        }
    }

    @media screen and (max-width: 425px) {
    }
`

const Theme = memo((props) => (
    <ThemeProvider theme={theme}>
        <GlobalStyle location={props.pathname} backgroundColor={props.backgroundColor} />
        {props.children}
    </ThemeProvider>
));

export default Theme;